body {
  margin: 0;
  min-height: 100%;
  font-family: "Mulish", sans-serif;
  font-weight: normal;
  background: linear-gradient(#f4f0f3, #eaf3f7) fixed;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
p {
  margin-block-start: 0px;
  margin-block-end: 0px;
  display: initial;
}
