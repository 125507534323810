@import "https://fonts.googleapis.com/css2?family=Mulish:wght@400;700&display=swap";
._loading_1b0d4b {
  max-width: 700px;
  margin: 100px auto;
  padding: 100px;
}

._eventList_1b0d4b {
  max-width: 700px;
  margin: 50px auto;
  border-radius: 6px;
  background: white;
  overflow: hidden;
  box-shadow: 0px 0px 8px 1px rgba(62, 118, 244, 0.14);
}

._eventItem_1b0d4b {
  margin: 1px;
  height: 90px;
  background: white;
  display: flex;
  border: 0px solid rgba(62, 118, 244, 0.14);
  box-shadow: 0px 1px 8px 1px rgba(62, 118, 244, 0.14);
  font-size: 14px;
}

._eventDate_1b0d4b {
  font-size: 12px;
  color: #b3b3b3;
}

._eventItem_1b0d4b:hover {
  background: #f5f5f5;
}

._eventDescription_1b0d4b {
  display: grid;
}

._eventDescription_1b0d4b p {
  padding-right: 20px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin-top: 25px;
  margin-bottom: 7px;
}

._eventDescription_1b0d4b p:hover {
  text-overflow: initial;
  overflow: auto;
}

._avatar_1b0d4b {
  height: 37px;
  width: 37px;
  margin-top: 27px;
  margin-bottom: 27px;
  margin-left: 20px;
  margin-right: 20px;
  border-radius: 100%;
  overflow: hidden;
  position: relative;
}

body {
  margin: 0;
  min-height: 100%;
  font-family: "Mulish", sans-serif;
  font-weight: normal;
  background: linear-gradient(#f4f0f3, #eaf3f7) fixed;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
p {
  margin-block-start: 0px;
  margin-block-end: 0px;
  display: initial;
}

/*# sourceMappingURL=index.357b6bf8.css.map */
